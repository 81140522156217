.hiring {
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: start;
  justify-content: space-evenly;
  border-radius: 6px;
  row-gap: 20px;
  column-gap: 25px;
  /* overflow: hidden; */
}

.hiring img {
  width: 160px;
  height: 79px;
  padding: 10px;
  margin: 20px;
}

.featured__card {
  background: linear-gradient(179deg, hsla(230, 100%, 98%, 1) 58%, hsla(0, 0%, 100%, 1) 44%);
}

/* hero css */
@media (max-width: 1024px) {
  .hero__img {
    margin-top: 20px;
    margin-left: -50px;
    width: auto;
  }
  .hiring {
    width: 90%;
    grid-template-columns: auto auto auto;
    /* padding: 10px; */
  }
  .featured__card {
    background: #f1f3ff;
    font-size: 42px;
    margin: 0px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.2px;
  }
}
.animate__style {
  font-weight: 900;
  /* font-size: 48px; */
  line-height: 1.4em;
  letter-spacing: -1px;
}
.animate::before {
  content: '';
  cursor: pointer;
}
.animate:after {
  content: '';
  float: left;
  background: linear-gradient(90deg, #2d23a6 0%, rgba(225, 14, 90, 0.99) 100%);
  width: 100%;
  height: 9px;
}
